.workout-modal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
    &.red-dot {
        background-color: #d64343;
      }
    &.green-dot {
    background-color: #15a52d;
  }
  }

.active-employee {
  background-color: #deeef7;
    border-radius: 10px;
}

.hover-employee {
  cursor: pointer;
  &:hover {
  background-color: #f1ebeb;

  }
}