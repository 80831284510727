.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .ant-layout {
    background-color: #f2f6f9;
  }
  
  .quill {
    &.error {
      .ql-snow {
        border-color: red !important;
        &:first-child {
          border-bottom-color: #ccc !important;
        }
        &:last-child {
          border-top-color: #ccc !important;
        }
      }
    }
  }
  
  .sider {
    overflow: auto;
    height: calc(100vh - 100px);
    position: fixed !important;
    left: 0;
    top: 100;
    bottom: 0;
    background-color: #fff !important;
    z-index: 10;
    .ant-menu-item {
      display: flex;
      font-weight: 600;
      color: #154d76;
      background: #eceeef;
      height: 45px;
      transition: 0.06s all;
  
      &-selected {
        background-color: #154d76;
        color: white;
      }
    }
    .ant-menu-item.ant-menu-item-active {
      background-color: #154d76 !important;
      color: white !important;
    }
  
    .ant-layout-sider-children {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  
    .ant-layout-sider {
      height: unset !important;
      overflow: unset !important;
    }
  
    &__search-bar-menu {
      display: flex;
      height: 7%;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  
    &__menu-side-bar {
      height: 93%;
      overflow-y: scroll;
  
      &__edit {
        margin-left: 4px;
        // width: 30px;
  
        // position: absolute;
        // right: 5px;
        // top: 27px;
        // float: right;
        // height: 45px;
      }
  
      &__label {
        overflow: inherit;
        // background-color: #282c34;
        flex: 11;
      }
    }
  
    &__button-create-rule {
      height: 5%;
    }
  
    &__menu-side-bar::-webkit-scrollbar {
      width: 3px;
    }
  
    &__menu-side-bar::-webkit-scrollbar-thumb {
      background: #6496c880;
    }
  }
  
  .siderTablet {
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #fff !important;
    .ant-menu-item {
      font-weight: 600;
      color: #154d76;
      background: #e3ecf3;
  
      &-selected {
        background-color: #154d76;
        color: white;
      }
    }
  
    .ant-layout-sider-children {
      height: 100vh;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  
    .ant-layout-sider {
      height: unset !important;
      overflow: unset !important;
    }
  
    &__search-bar-menu {
      height: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  
    &__menu-side-bar {
      height: 93%;
      overflow-y: scroll;
  
      &__edit {
        float: right;
        height: 40px;
      }
    }
  
    &__button-create-rule {
      height: 5%;
    }
  
    &__menu-side-bar::-webkit-scrollbar {
      width: 3px;
    }
  
    &__menu-side-bar::-webkit-scrollbar-thumb {
      background: #6496c880;
    }
  }
  
  .site-layout {
    min-height: 100vh;
    max-height: max-content;
    &__header {
      position: sticky;
      top: 0;
      z-index: 1;
      width: 100%;
  
      &__logo {
        float: left;
      }
  
      &__title {
        text-align: right;
        padding-left: 20px;
        line-height: 1.2;
      }
  
      &__name {
        float: right;
      }
  
      &__menu {
        display: none;
        width: 31px;
        font-size: x-large;
        color: white;
        margin: auto;
        text-align: end;
      }
    }
  
    &__content {
      margin-left: 270px;
      padding: 16px;
  
      &__input {
        margin-bottom: 16px;
        font-weight: bold;
  
        input {
          margin: unset;
        }
      }
  
      &__button-div {
        margin-top: 16px;
        height: 35px;
      }
  
      &__button-save {
        font-weight: bold;
        background-color: #154d76;
        float: right;
      }
  
      &__button-delete {
        font-weight: bold;
        background-color: #ec4242;
        float: left;
      }
  
      &__addition-block {
        // position: sticky !important;
        // top: 116px;
        margin-bottom: 16px;
  
        &__title {
          font-weight: bold;
          margin: 5px 0;
        }
  
        &__label {
          margin-bottom: 1.4em;
  
          &__footer {
            line-height: 3px;
          }
        }
      }
  
      &__import-block {
        // margin-top: 16px;
  
        &__button {
          margin: unset;
        }
      }
    }
  }
  
  .import-item {
    position: relative;
    border: 1px solid rgb(68, 92, 228);
    padding: 4px;
    border-radius: 5px;
    margin: 6px;
  
    &-del {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  
  .edit-icon {
    flex: 1;
  }
  
  .ant-menu-title-content {
    max-width: 220px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .layout-content-block {
    margin-top: 50px;
    height: calc(100vh - 50px);
    padding: 16;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .ant-layout .ant-layout-header {
    height: 100px;
    background: #154d76;
    line-height: 1;
    display: flex;
    align-items: center;
  }
  
  .text-style-shadow.h1 {
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
  }
  
  .text-style-shadow.h3 {
    color: #fff;
    font-weight: 600;
    font-size: 1.3rem;
  }
  
  .text-style-shadow.h4 {
    color: #fff;
    font-weight: 500;
    font-size: 0.8rem;
  }
  
  .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .container h3 {
    margin: 0 0 16px 0;
  }
  
  .container p {
    margin: 0 0 5px 0;
  }
  
  .button-import {
    margin-top: 2em;
  }
  
  @media (max-width: 1025px) {
    .sider {
      display: none;
    }
  
    .site-layout {
      &__header {
        &__menu {
          display: block;
        }
      }
      &__content {
        margin-left: 0px;
      }
    }
  }
  
  @media (max-width: 576px) {
    .ant-layout .ant-layout-header {
      padding: 16px;
    }
  
    .site-layout {
      &__header {
        &__logo img {
          width: 65px;
        }
  
        &__title {
          // font-size: 16px;
        }
  
        &__name {
          // float: right;
        }
  
        &__menu {
          // display: none;
          // width: 31px;
          // font-size: x-large;
          // color: white;
          // margin: auto;
          // text-align: end;
        }
      }
    }
    .text-style-shadow.h1 {
      font-size: 1.2rem;
    }
  
    .container {
      padding: 16px;
    }
  }
  
  @media (max-width: 450px) {
    .ant-layout .ant-layout-header {
      height: 65px;
      padding: 16px;
    }
  
    .site-layout {
      &__header {
        &__logo img {
          width: 50px;
        }
  
        &__title {
          padding-left: 10px;
          // font-size: 16px;
        }
  
        &__name {
          // float: right;
        }
  
        &__menu {
          // display: none;
          // width: 31px;
          // font-size: x-large;
          // color: white;
          // margin: auto;
          // text-align: end;
        }
      }
    }
    .text-style-shadow.h1 {
      font-size: 1rem;
    }
  
    .text-style-shadow.h4 {
      font-size: 0.7rem;
    }
  
    .container {
      padding: 10px;
    }
  }
  
  @media (max-width: 350px) {
    .ant-layout .ant-layout-header {
      height: 65px;
      padding: 16px;
    }
  
    .site-layout {
      &__header {
        &__logo img {
          width: 40px;
        }
  
        &__title {
          padding-left: 8px;
          // font-size: 16px;
        }
  
        &__name {
          // float: right;
        }
  
        &__menu {
          font-size: large;
          // display: none;
          // width: 31px;
          // font-size: x-large;
          // color: white;
          // margin: auto;
          // text-align: end;
        }
      }
    }
    .text-style-shadow.h1 {
      font-size: 0.8rem;
    }
  
    .text-style-shadow.h4 {
      font-size: 0.5rem;
    }
  
    .container {
      padding: 10px;
    }
  }
  
  .sider__menu-side-bar, .site-layout__header {
    z-index: 5;
  }
  /* @media (min-width: 476px) {
      .container {
          padding-right: 15px;
          padding-left: 15px
      }
    }
    
    @media (min-width: 768px) {
      .container {
          padding-right: 15px;
          padding-left: 15px
      }
    }
    
    @media (min-width: 992px) {
      .container {
          padding-right: 15px;
          padding-left: 15px
      }
    }
    
    @media (min-width: 1200px) {
      .container {
          padding-right: 15px;
          padding-left: 15px
      }
    }
    
    @media (min-width: 476px) {
      .container {
          width: 100%
      }
    }
    
    @media (min-width: 768px) {
      .container {
          width: 720px;
          max-width: 100%
      }
    }
    
    @media (min-width: 992px) {
      .container {
          width: 960px;
          max-width: 100%
      }
    }
    
    @media (min-width: 1200px) {
      .container {
          width: 1140px;
          max-width: 100%
      }
    }
    
    @media (min-width: 1400px) {
      .container {
          width: 1340px;
          max-width: 100%
      }
    } */
  
    .scrollbar-custom
{
	margin-left: 30px;
	float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
	min-height: 450px;
}

.style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}