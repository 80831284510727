@import "../../index.scss";

.cl-flex-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0.7143rem 0;
}
.cl-description-col {   
  min-width: 100px;
  padding-left: 0.7143rem;
  @include desktop {     
    min-width: 200px;   
  }    
  // @include table {     
  //  font-size: 13px;   
  // } 
 }

 .cl-col-responsive {
  max-width: 100%;
  flex-grow: 1;
  white-space: nowrap;
  position: relative;
}

.cl-flex-grow-0 {
  flex-grow: 0!important;
}

.cl-project-col {
  min-width: 100px;
  display: flex;
  align-items: center;
  overflow: visible;
}

.cl-col-responsive {
  max-width: 100%;
  flex-grow: 1;
  white-space: nowrap;
  position: relative;
}

.cl-w-100 {
  width: 100%!important;
}
.cl-d-flex {
  display: flex!important;
  gap: 5px;
}
.cl-dropup, .cl-dropright, .cl-dropdown, .cl-dropleft {
  position: relative;
}
.cl-cut-text, .cl-input-description, .cl-badge-tag-timetracker-entry, .time-tracker-recorder .cl-badge-tag, .time-tracker-recorder .cl-badge-same-entries {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cl-w-100-hidden {
  overflow: hidden;
  max-width: 100%;
}
.cl-justify-content-center {
  justify-content: center!important;
}

.cl-col-fixed {
  display: flex;
  white-space: nowrap;
}
.cl-component-divided-left {
  display: inline-flex;
  align-items: center;
}

.custom-picker input {
  visibility: hidden;
}

.time-input {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
}

.action-btn {   
  padding: 0.678rem 1.4286rem;
  margin: 0;
  font-weight: 400;
  color: #666;
  background-color: transparent;
  cursor: pointer;
}
.action-btn:hover {   
  background: #E4EAEE;
  color: #333;
}

.expend-icon {
  background: #154d76;
  width: 23px;
  height: 23px;
  margin: auto;
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
}

.visible-time {
animation-name: visibleAnimation;
animation-duration: 0.5s;
}
.tag-custom{
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #e3e6ef;
  border-radius: 6px;
  padding: 7px;
  font-size: 90%;
}

@keyframes visibleAnimation {
from {opacity: 0.2;}
to {opacity: 1;}
}