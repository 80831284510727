
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* cho  Internet Explorer, Edge */
  scrollbar-width: none; /* cho Firefox */
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none; /* cho Chrome, Safari, and Opera */
}

#style-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-scroll::-webkit-scrollbar-thumb {
  background-color: #00000040;
  border-radius: 10px;
}

.mb-0{margin-bottom: 0 !important;}
.font-bold{font-weight: bold;}
.pd-left-5 {
  padding-left: 5px !important;
}
.fs-18 {
  font-size: 18px !important; 
}
.new-task {
  color: red;
  font-size: 18px;
  font-weight: 600;
  animation: example 1s linear infinite alternate;
}
@keyframes example {
 from  {opacity: 0;}
to  {opacity: 1;}
}