.btn-icon {
  border: 1px solid #cccccc;
  padding: 5px;
  &.next-icon {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.rbc-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between !important; 
  .today {
  border: 1px solid #cccccc;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  }
}