.workout-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  &.red-dot {
    background-color: #d64343;
  }
  &.green-dot {
    background-color: #15a52d;
  }
  &.yellow-dot {
    background-color: #ffd105;
  }
}

.color {
  &-red {
    color: #d64343;
  }
  &-green {
    color: #15a52d;
  }
  &-yellow {
    color: #ffd105;
  }
}
.active-employee {
  background-color: #deeef7;
  border-radius: 10px;
}

.hover-employee {
  cursor: pointer;
  &:hover {
    background-color: #f1ebeb;
  }
}

.clock-in-after-7 {
  background-color: #d64343;
}
.custome-timeline.ant-timeline-item {
 padding-bottom: 5px !important;
}